import React, { useState } from "react";
import BackSlider from "../../components/Login/BackSlider";
import CardRound from "../../components/Login/CardRound";
import bg1 from "../../../src/images/login/bgs/bg-1.jpg";
import bg2 from "../../../src/images/login/bgs/bg-2.jpg";
import { Link, navigate } from "gatsby";
import { Formik, Field } from "formik";
import { ToastContainer } from "react-toastify";
import Loading from "../../components/Login/Loading";
import SEO from "../../components/SEO";


const Assinatura = (props) => {
    const [isLoading, setisLoading] = useState(false);
    const onSubmit = async (values, { setFieldError, setSubmitting }) => {
        setSubmitting(true);
        setisLoading(true);
        window.localStorage.setItem("dados-assinatura", JSON.stringify(values));
        navigate("/assinatura-de-email/resultado", { state: values })
        return false;
    };

    const generateEmail = (name) => {
        let names = name.split(" ");
        let newEmail = (names[0].charAt(0) + names[names.length - 1] + "@vendemmia.com.br").toLowerCase();
        return newEmail;

    }

    const bgs = [
        {
            urlBg: bg1,
            text: "Seja bem vindo à Vendemmia!",
            timeSecs: 5,
            topicId: 2, // 1- Navio, 2 - Caminhão, 3 - Armazém, null - Ocultar
            filter: 0 // 0 - Desativado, qualquer outro valor é o brilho do filtro. (Atualmente desativado)
        },
        {
            urlBg: bg2,
            text: "Crie aqui sua nova assinatura de email!",
            timeSecs: 5,
            topicId: null,
            filter: 0
        }
    ]
    return (
        <BackSlider slides={bgs}>
            <SEO title="Assinatura de e-mail - Crie a sua" />

            <CardRound hideMobile={true}>
                <Formik
                    initialValues={props.location?.state ? props.location?.state : {
                        name: "",
                        office: "",
                        email: "",
                        phone: "",
                        address: ""
                    }}
                    onSubmit={onSubmit}>
                    {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
                        <form
                            onSubmit={e => {
                                e.preventDefault();
                                handleSubmit();
                            }}>
                            <div className="field">
                                <label htmlFor="name">
                                    Nome
                                </label>
                                <Field
                                    id="name"
                                    type="text"
                                    name="name"
                                    autocomplete="off"
                                    placeHolder="Digite seu nome completo"
                                    onBlur={(n) => values.email = generateEmail(n.target.value)}
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="office">
                                    Cargo
                                </label>
                                <Field
                                    id="office"
                                    type="text"
                                    name="office"
                                    autocomplete="off"
                                    placeHolder="Digite sua área e cargo"
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="email">
                                    E-mail
                                </label>
                                <Field
                                    id="email"
                                    type="text"
                                    name="email"
                                    autocomplete="off"
                                    placeHolder="Digite seu email"
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="phone">
                                    Phone
                                </label>
                                <Field
                                    id="phone"
                                    type="text"
                                    name="phone"
                                    autocomplete="off"
                                    placeHolder="Digite seu número de telefone"
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="address">
                                    End.
                                </label>
                                <Field
                                    as="select"
                                    id="address"
                                    type="text"
                                    name="address"
                                >
                                    <option
                                        value=""
                                    >
                                        Selecione um endereço
                                    </option>
                                    <option
                                        value="Rua Funchal, 129 – 13° Andar São Paulo, SP, Brasil, CEP: 04551-060"
                                    >
                                        São Paulo (Vila Olímpia)
                                    </option>
                                    <option
                                        value="Av Marcos Conder, 805 – 8° Andar -  Centro – Itajaí, SC, Brasil, CEP: 88301-123"
                                    >
                                        Santa Catarina (Itajaí)
                                    </option>
                                    <option
                                        value="Rodovia BR 470 – KM 7, 6800 - Volta Grande, Navegantes/SC CEP: 88371-890"
                                    >
                                        Armazém SC CD1 (BR 470 – KM 7, 6800)
                                    </option>
                                    <option
                                        value="Rodovia BR 470 – 4669 - Volta Grande, Navegantes/SC CEP: 88371-890"
                                    >
                                        Armazém SC CD2 (BR 470 – 4669)
                                    </option>
                                    <option
                                        value="Avenida Portugal, 325 - Bairro Itaqui, Itapevi"
                                    >
                                        Armazém SP (Itapevi)
                                    </option>
                                </Field>
                            </div>

                            <div className="flex justify-between w-full mt-6">
                                <Link to={`/`} className="remember" state={{ email: values.email }}>
                                    Voltar ao login
                                </Link>
                                <button
                                    type="submit"
                                    className="btn"
                                >
                                    Criar assinatura
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </CardRound>
            <ToastContainer />
            <Loading status={isLoading} />
        </BackSlider>
    );
};

export default Assinatura;
